import { AnyAction, combineReducers, Reducer } from 'redux'
import { RootStateInterface } from '@/states/interfaces/rootState'

import layoutReducer from './layout.reducer'
import charactersReducer from './characters.reducer'

const rootReducer: Reducer<RootStateInterface, AnyAction> =
  combineReducers<RootStateInterface>({
    layout: layoutReducer,
    characters: charactersReducer,
  })

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
