import {
  ActionsLayout,
  ActionTypesLayout,
  LayoutState,
} from '@/states/interfaces'

export const initialState: LayoutState = {
  nav: false,
}

interface HydratePayload {
  layout: LayoutState
}

const layoutReducer = (
  state = initialState,
  action: ActionsLayout
): LayoutState => {
  switch (action.type) {
    // Nav Toggle
    case ActionTypesLayout.TOGGLE_NAV: {
      return {
        ...state,
        nav: action.payload.nav,
      }
    }
    default:
      return state
  }
}

export default layoutReducer
