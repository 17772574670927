import { CharacterResponseData } from '@/types'
import { AppDispatch } from '@/states/store'
import { ActionTypesCharacters } from '@/states/interfaces/characters/charactersAct.interfaces'

export const updateCharacters =
  (list: CharacterResponseData[], validateAt: string) =>
  (dispatch: AppDispatch) => {
    return dispatch({
      type: ActionTypesCharacters.UPDATE,
      payload: {
        list,
        validateAt,
      },
    })
  }
