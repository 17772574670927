import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Provider } from 'react-redux'
import { store } from '@/states/store'
import { Helmet } from 'react-helmet'
import ReactGA from 'react-ga'

import Home from './pages/Home'

if (process.env.REACT_APP_GOOGLE_TAG_ID) {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_TAG_ID as string)
}

function App() {
  return (
    <Provider store={store}>
      <Helmet>
        {!!process.env.REACT_APP_STATUSPAGE_ID && (
          <script
            src={`https://${process.env.REACT_APP_STATUSPAGE_ID}.statuspage.io/embed/script.js`}
          />
        )}
      </Helmet>
      <BrowserRouter>
        <Routes>
          <Route index path="/" element={<Home />} />
        </Routes>
      </BrowserRouter>
    </Provider>
  )
}

export default App
