import { CharacterResponseData } from '@/types'

export enum ActionTypesCharacters {
  UPDATE = 'CHARACTER_UPDATE', //페치 받아서 리스트 집어넣기
  FIND = 'CHARACTER_FIND', // uid 사용해서 하나 찾아오기
}

//여러개 추가될때는 OR 연산자로 추가해줌
export type ActionsCharacters = UpdateCharacters

export interface UpdateCharacters {
  type: ActionTypesCharacters.UPDATE
  payload: {
    list: CharacterResponseData[]
    validateAt: string
  }
}
