import React, { useEffect, useState } from 'react'
import { DEFAULT_BUBBLE_SIZE, hideBoard, resetBubblePositions } from '@/utils'

const BubbleYear = ({ year, uid }: { year: string; uid: string }) => {
  const [isDataLoaded, setIsDataLoaded] = useState(false)
  useEffect(() => {
    if (year && uid) {
      setIsDataLoaded(true)
    }
  }, [year, uid])

  const moveScroll = () => {
    //항상 기본사이즈에서 이동한다
    const thisBubble = document.getElementById(uid)

    const windowWidth = window.innerWidth
    const bubbleLocation = thisBubble?.getBoundingClientRect().left as number

    const bubbleBox = document.getElementById('homeBubbleBox')
    const bubbleBoxLocation = bubbleBox?.scrollLeft as number
    if (bubbleBox != null) {
      const offset = Math.trunc((windowWidth - DEFAULT_BUBBLE_SIZE) / 2)
      // console.log('offset - ' + offset)
      // console.log('bubbleLocation - ' + bubbleLocation)
      // console.log('bubbleBoxLocation - ' + bubbleBoxLocation)

      let move = offset - bubbleLocation
      // console.log('move - ' + move)

      bubbleBox.scrollLeft = bubbleBoxLocation - move
    }
  }

  return (
    <span
      onClick={() => {
        hideBoard()
        resetBubblePositions()
        setTimeout(() => {
          moveScroll()
        }, 350)
      }}
      className="year-span select-none block cursor-pointer w-2/3 text-4xl text-center font-kbo font-extralight"
      data-uid={uid}>
      {year}
    </span>
  )
}

export default BubbleYear
