import React, { useEffect, useState } from 'react'
import Title from '@/components/Title'
import BubbleBox from '@/components/BubbleBox'
import { CharacterResponseResult } from '@/types'
import BubbleBottom from '@/components/BubbleBottom'
import { useAppDispatch } from '@/states/store'
import { updateCharacters } from '@/states/actions/characters.actions'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '@/states/reducers'
import axios from 'axios'
import {
  ACCESS_KEY,
  DEFAULT_PAGE_NO,
  DEFAULT_PAGE_SIZE,
  getValidateDate,
  MEMEX_SERVER_PATH_CONTENT,
} from '@/utils'

const Home = () => {
  const dispatch = useAppDispatch()
  const [isDataLoaded, setIsDataLoaded] = useState(false)
  const [characterData, setCharacterData] = useState<CharacterResponseResult>()

  // State (Redux)
  const { charactersState } = useSelector(
    (state: RootState) => ({
      charactersState: state.characters,
    }),
    shallowEqual
  )
  const { list, validateAt } = charactersState

  const requestHeader = {
    'Content-Type': 'application/json',
    'Access-Token': ACCESS_KEY,
  }

  const callCharacterData = async () => {
    try {
      return await axios.post(
        MEMEX_SERVER_PATH_CONTENT,
        {
          size: DEFAULT_PAGE_SIZE,
          page: DEFAULT_PAGE_NO,
        },
        {
          headers: requestHeader,
        }
      )
    } catch (error) {
      console.log(error)
    }
  }

  const buildData = async () => {
    const res = await callCharacterData()
    if (res) {
      setCharacterData(res.data)
    }
  }

  useEffect(() => {
    buildData()
  }, [])

  useEffect(() => {
    if (characterData != null) {
      setIsDataLoaded(true)
      const newValidateDate = getValidateDate(characterData.validateAt)
      if (newValidateDate !== validateAt) {
        dispatch(updateCharacters(characterData.list, newValidateDate))
      }
    }
  }, [characterData])

  useEffect(() => {
    const scrollContainer = document.getElementById('homeBubbleBox')

    scrollContainer?.addEventListener('wheel', (e) => {
      e.preventDefault()
      scrollContainer.scrollLeft += e.deltaY * 5

      e.stopPropagation()
    })
  }, [])

  return (
    <div className="w-full bg-[#F8F2E9]">
      <Title />
      <div
        id="homeBubbleBox"
        className="relative h-screen overflow-x-scroll scroll-smooth">
        <BubbleBox list={list} />
        <BubbleBottom list={list} />
      </div>
    </div>
  )
}

export default Home
