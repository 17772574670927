import { CharactersState } from '@/states/interfaces/characters/characters.interfaces'
import {
  ActionsCharacters,
  ActionTypesCharacters,
} from '@/states/interfaces/characters/charactersAct.interfaces'

export const initialState: CharactersState = {
  list: [],
  validateAt: '',
}

const charactersReducer = (
  state = initialState,
  action: ActionsCharacters
): CharactersState => {
  switch (action.type) {
    case ActionTypesCharacters.UPDATE: {
      return {
        ...state,
        list: action.payload.list,
        validateAt: action.payload.validateAt,
      }
    }
    default:
      return state
  }
}

export default charactersReducer
