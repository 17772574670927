import React, { useEffect, useState } from 'react'
import { TitleResponseData, TitleResponseResult } from '@/types'
import {
  ACCESS_KEY,
  DEFAULT_PAGE_NO,
  DEFAULT_PAGE_SIZE,
  MEMEX_SERVER_PATH_DESC,
} from '@/utils'
import axios from 'axios'

const Title = () => {
  const [isDataLoaded, setIsDataLoaded] = useState(false)
  const [titleData, setTitleData] = useState<TitleResponseResult>()
  const [validDate, setValidDate] = useState('')
  const [data, setData] = useState<TitleResponseData>()

  const requestHeader = {
    'Content-Type': 'application/json',
    'Access-Token': ACCESS_KEY,
  }
  const callTitleData = async () => {
    try {
      return await axios.post(
        MEMEX_SERVER_PATH_DESC,
        {
          size: DEFAULT_PAGE_SIZE,
          page: DEFAULT_PAGE_NO,
        },
        {
          headers: requestHeader,
        }
      )
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    buildData()
  }, [validDate, data])

  const buildData = async () => {
    const res = await callTitleData()
    if (res) {
      if (validDate != res.data.validateAt) {
        setValidDate(res.data.validateAt)
        if (res.data.list.length > 0) {
          setData(res.data.list[0])
        }
      }
    }
  }

  return (
    <div className="w-full h-[120px] fixed flex justify-center items-center bg-[#F9F6F2] border-b-2 border-[#555555]">
      <span className="relative block text-center text-6xl cursor-default select-none font-kbo font-semibold">
        {data?.data?.title?.KO}
      </span>
      <span className="absolute right-[27px] w-[25%] text-right font-js font-extralight break-words clamp">
        {data?.data?.description?.KO}
      </span>
    </div>
  )
}

export default Title
