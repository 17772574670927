import { useEffect, useState } from 'react'
import { Character } from '@/types'
import { hideBoard, resetBubblePositions } from '@/utils'

/**
 * 1. 리덕스를 사용하여 언어 설정 확인
 * 2. fixed 설정을 통해 화면 오른쪽 가장 위에 보여준다.
 */
const BubbleBoard = ({ character }: { character: Character | null }) => {
  const [language, setLanguage] = useState('KO')
  const [isDataLoaded, setIsDataLoaded] = useState(false)

  useEffect(() => {
    if (character) {
      setIsDataLoaded(true)
    }
  }, [character])

  return (
    <div
      className="bubble-board hide fixed right-0 w-[32%] overflow-y-scroll bg-[#F9F6F2] border-l-2 border-[#555555]"
      onClick={(e) => e.stopPropagation()}>
      <div className="relative w-full h-full">
        <div className="w-full h-10">
          <span
            className="cursor-pointer inline-block text-4xl font-extrabold text-center align-middle float-right px-5"
            onClick={() => {
              hideBoard()
              resetBubblePositions()
            }}>
            ⨉
          </span>
        </div>
        <div className="board-content">
          <span className="font-normal">제목</span>
          <span className="font-extralight">{character?.title?.KO}</span>
        </div>
        <div className="board-content">
          <span className="font-normal">연도</span>
          <span className="font-extralight">{character?.year?.KO}</span>
        </div>
        <div className="board-content">
          <span className="font-normal">{character?.article1?.KO}</span>
          <span className="font-extralight">{character?.content1?.KO}</span>
        </div>
        <div className="board-content">
          <span className="font-normal">{character?.article2?.KO}</span>
          <span className="font-extralight">{character?.content2?.KO}</span>
        </div>
        <div className="board-content">
          <span className="font-normal">{character?.article3?.KO}</span>
          <span className="font-extralight">{character?.content3?.KO}</span>
        </div>
        <div className="board-content">
          <span className="font-normal">{character?.article4?.KO}</span>
          <span className="font-extralight">{character?.content4?.KO}</span>
        </div>
        <div className="board-content">
          <span className="font-normal">{character?.article5?.KO}</span>
          <span className="font-extralight">{character?.content5?.KO}</span>
        </div>
      </div>
    </div>
  )
}

export default BubbleBoard
