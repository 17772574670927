import React, { useEffect, useState } from 'react'
import Bubble from '@/components/Bubble'
import { Character, CharacterResponseData } from '@/types'
import BubbleBoard from '@/components/BubbleBoard'
import { hideBoard, resetBubblePositions } from '@/utils'

/**
 * if overflow - 왼쪽 정렬
 * else - 가운데 정렬
 *
 * list 받아서 크기 bubble 컴포넌트 생성
 * 가운데 온 Bubble 커지게
 */

const BubbleBox = ({ list }: { list: CharacterResponseData[] }) => {
  const [isDataLoaded, setIsDataLoaded] = useState(false)
  const [bubbleUid, setBubbleUid] = useState('')
  const [character, setCharacter] = useState<Character | null>(null)

  useEffect(() => {
    if (list.length > 0) {
      setIsDataLoaded(true)
      list = list.sort((a, b) => a.data.order - b.data.order)
    }
  }, [list])

  useEffect(() => {
    const clickedData = list.find(
      (item) => item.uid === bubbleUid
    ) as CharacterResponseData
    setCharacter(clickedData?.data)
  }, [bubbleUid])

  return (
    <div
      className="relative flex pt-[120px] px-[50%] w-max"
      onClick={() => {
        hideBoard()
        resetBubblePositions()
      }}>
      <div className="bubble-cover flex items-center justify-center">
        {list.map((item) => renderBubble(item, setBubbleUid))}
      </div>
      <BubbleBoard character={character} />
    </div>
  )
}

const renderBubble = (item: CharacterResponseData, setBubbleUid) => {
  return (
    <div key={item.uid}>
      <Bubble
        character={item.data}
        uid={item.uid}
        order={item.data.order}
        submitUid={setBubbleUid}
      />
    </div>
  )
}

export default BubbleBox
