// 상수
// Project UID
export const PROJECT_UID: string = process.env.REACT_APP_PROJECT_UID as string

// 미믹스 axios
export const MEMEX_SERVER_PATH: string = `${process.env.REACT_APP_API_URL}/projects/{1}/models/{2}/contents/search/v1`
export const MEMEX_SERVER_PATH_DESC: string = `${process.env.REACT_APP_API_URL}/projects/${PROJECT_UID}/models/timeline-description/contents/search/v1`
export const MEMEX_SERVER_PATH_CONTENT: string = `${process.env.REACT_APP_API_URL}/projects/${PROJECT_UID}/models/timeline-contents/contents/search/v1`
export const MODEL_KEY_DESC: string = 'timeline-description'
export const MODEL_KEY_CONTENT: string = 'timeline-contents'
export const DEFAULT_PAGE_SIZE: number = 9999
export const DEFAULT_PAGE_NO: number = 0
export const ACCESS_KEY: string = process.env.REACT_APP_ACCESS_TOKEN as string

// react 상수
export const DEFAULT_BUBBLE_SIZE: number = 400
export const SCALE_SIZE: number = Math.trunc(DEFAULT_BUBBLE_SIZE * 1.5)

//api valid
export const getValidateDate = (validDate?: string) => {
  if (validDate === null || validDate === undefined) {
    return ''
  } else {
    return validDate
  }
}

//bubble board
export const showBoard = () => {
  const board = document.querySelector('.bubble-board')
  board?.classList.remove('hide')
  board?.classList.add('show')
  board?.addEventListener('wheel', (e) => {
    e.preventDefault()

    // @ts-ignore
    board.scrollTop += e.deltaY
    e.stopPropagation()
  })
}
export const hideBoard = () => {
  const board = document.querySelector('.bubble-board')
  board?.classList.remove('show')
  board?.classList.add('hide')
  if (board != null) {
    board.scrollTop = 0
  }

  board?.removeEventListener('wheel', () => {}, false)
}

//bubbles
export const resetBubblePositions = () => {
  const bubbles = Array.from(document.querySelectorAll('.bubble-inner'))
  const bubbleYears = Array.from(document.querySelectorAll('.bubble-year'))
  bubbles?.forEach((item) => {
    item.classList.remove('selected')
    item.classList.add('bubble-move-center')
    item.classList.remove('bubble-move-left', 'bubble-move-right')
  })

  bubbleYears?.forEach((item) => {
    item.classList.remove('selected')
    item.classList.add('bubble-move-center')
    item.classList.remove('bubble-move-left', 'bubble-move-right')
  })
  resetBubbleTitles()
}

export const showBubbleTitle = (bubble?: Element) => {
  bubble?.querySelector('.bubble-title')?.classList.remove('hide')
}

export const hideBubbleTitle = (bubble?: Element) => {
  bubble?.querySelector('.bubble-title')?.classList.add('hide')
}

export const resetBubbleTitles = () => {
  document
    .querySelectorAll('.bubble-title')
    .forEach((item) => item.classList.remove('hide'))
}

export const moveBubbleSiblings = (
  order: number,
  bubbles: Element[],
  bubbleYears: Element[]
) => {
  toggleBubbleMove(bubbles, order)
  toggleBubbleMove(bubbleYears, order)
}

export const toggleBubbleMove = (list: Element[], order: number) => {
  list?.forEach((item) => {
    const itemOrder = Number(item.getAttribute('data-order'))
    if (itemOrder != null) {
      if (itemOrder < order) {
        item.classList.remove('bubble-move-center')
        item.classList.add('bubble-move-left')
      } else if (itemOrder > order) {
        item.classList.remove('bubble-move-center')
        item.classList.add('bubble-move-right')
      }
    }
  })
}
