import React, { useEffect, useState } from 'react'
import { CharacterResponseData } from '@/types'
import BubbleYear from '@/components/BubbleYear'

const BubbleBottom = ({ list }: { list: CharacterResponseData[] }) => {
  const [isDataLoaded, setIsDataLoaded] = useState(false)

  useEffect(() => {
    if (list.length > 0) {
      setIsDataLoaded(true)
      list = list.sort((a, b) => a.data.order - b.data.order)
      storeYear = 0
    }
  }, [list])

  let storeYear = 0

  return (
    <div className="relative h-[80px] px-[50%] bottom-0 border-t-2 border-[#555555] w-max">
      <div
        className="bubble-bottom h-[80px] relative flex items-center"
        id="bubbleBottom">
        {list.map((item) => {
          const itemYear = Number(item.data.year.KO)
          if (itemYear > storeYear) {
            storeYear = itemYear
            return renderVisibleYear(item)
          } else {
            return renderInvisibleYear(item)
          }
        })}
      </div>
    </div>
  )
}

const renderVisibleYear = (data: CharacterResponseData) => {
  return (
    <div
      key={'year-'.concat(data.uid)}
      data-order={data.data.order}
      className="bubble-year relative flex justify-center w-[400px] mx-6">
      <BubbleYear year={String(data.data.year.KO)} uid={data.uid} />
    </div>
  )
}

const renderInvisibleYear = (data: CharacterResponseData) => {
  return (
    <div
      key={'year-'.concat(data.uid)}
      data-order={data.data.order}
      className="bubble-year invisible -z-[9999] relative flex justify-center w-[400px] mx-6">
      <BubbleYear year={String(data.data.year.KO)} uid={data.uid} />
    </div>
  )
}

export default BubbleBottom
